import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Select,
  Result,
  Layout,
  Menu,
  Button,
  FloatButton,
  ConfigProvider,
  Modal,
} from "antd";
import {
  BankOutlined,
  AlertOutlined,
  LineChartOutlined,
  TeamOutlined,
  HistoryOutlined,
  CreditCardOutlined,
  CodeOutlined,
  FundOutlined,
  SolutionOutlined,
  ApiOutlined,
  StockOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileMarkdownOutlined,
  BarChartOutlined,
  SnippetsOutlined,
  ShoppingCartOutlined,
  TableOutlined,
  CommentOutlined,
  ProductOutlined,
  BlockOutlined,
  ShopOutlined,
  ShoppingOutlined,
  ContainerOutlined,
  AuditOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  ClusterOutlined,
  IdcardOutlined,
  FileDoneOutlined,
  BuildOutlined,
} from "@ant-design/icons";
import {
  TableCategoriesPage,
  TableNomenclature,
  TableOrganizations,
  TableContracts,
  TableWarehouses,
  TablePrices,
  TablePricesHandsontable,
  TableLoyalitySettings,
  TableLoyalityReport,
  TableLoyalityCards,
  TableContragents,
  TableProjects,
  TablePurchases,
  TableDocsWarehouse,
  TablePayboxes,
  TableUsers,
  TableEvents,
  TableIntegrations,
  TableDocsSales,
  TableWarehousesBalance,
  TableLoyalityTransactions,
  TableAnalystics,
  RentalChart,
  Settings,
  AmoTriggers,
} from "./pages";
import { CBSelect, AuthError, LoadingState } from "./shared";
import { apiRequests } from "./shared/api/api";
import TablePriceType from "./pages/TablePriceType/ui/TablePriceType";
import Loading from "./shared/ui/Loading";
import ruRU from "antd/locale/ru_RU";
import TableContragentsHandsontable from "./pages/TableContragentsHandsontable/TableContragentsHandsontable";
import PaymentsWrapper from "./pages/TablePayments/PaymentsWrapper";
import { CookieAcceptNotification } from "./enitities/Cookie";

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const queryClient = new QueryClient();

apiRequests.setToken(params.token);

const nav_items = (token) => [
  {
    label: <Link to={`/?token=${token}`}>Платежи</Link>,
    key: "payments",
    icon: <LineChartOutlined />,
  },

  {
    label: <Link to={`/payboxes?token=${token}`}>Счета</Link>,
    key: "payboxes",
    icon: <CreditCardOutlined />,
  },

  {
    label: <Link to={`/analytecs?token=${token}`}>Аналитика</Link>,
    key: "analytecs",
    icon: <StockOutlined />,
  },

  {
    label: "Продажи",
    key: "sales",
    icon: <AuditOutlined />,
    children: [
      {
        label: <Link to={`/docs_sales?token=${token}`}>Продажи</Link>,
        key: "docs_sales",
        icon: <SnippetsOutlined />,
      },
      {
        label: <Link to={`/contracts?token=${token}`}>Договоры</Link>,
        key: "contracts",
        icon: <SnippetsOutlined />,
      },
    ],
  },

  {
    label: <Link to={`/docs_purchases?token=${token}`}>Закупки</Link>,
    key: "docs_purchases",
    icon: <ShoppingCartOutlined />,
  },

  {
    label: "Склад",
    key: "warehouse",
    icon: <ProductOutlined />,
    children: [
      {
        label: <Link to={`/docs_warehouse?token=${token}`}>Документы</Link>,
        key: "docs_warehouse",
        icon: <SnippetsOutlined />,
      },
      {
        label: <Link to={`/warehouses?token=${token}`}>Помещения</Link>,
        key: "warehouses",
        icon: <ShopOutlined />,
      },
      {
        label: <Link to={`/warehouses_balances?token=${token}`}>Остатки</Link>,
        key: "warehouses_balances",
        icon: <BlockOutlined />,
      },
    ],
  },
  {
    label: "Товары и услуги",
    key: "goods_and_services",
    icon: <ShoppingOutlined />,
    children: [
      {
        label: <Link to={`/prices?token=${token}`}>Цены</Link>,
        key: "prices",
        icon: <SnippetsOutlined />,
      },
      {
        label: <Link to={`/price_types?token=${token}`}>Типы цен</Link>,
        key: "price_types",
        icon: <ReconciliationOutlined />,
      },
      {
        label: (
          <Link to={`/nomenclature_handsontable?token=${token}`}>Данные</Link>
        ),
        key: "nomenclature_handsontable",
        icon: <TableOutlined />,
      },
      {
        label: <Link to={`/categories?token=${token}`}>Категории</Link>,
        key: "categories",
        icon: <ContainerOutlined />,
      },
      {
        label: <Link to={`/nomenclature?token=${token}`}>Номенклатура</Link>,
        key: "nomenclature",
        icon: <FileDoneOutlined />,
      },
    ],
  },
  {
    label: "Карты лояльности",
    key: "loyality_settings",
    icon: <CreditCardOutlined />,
    children: [
      {
        label: <Link to={`/loyality_cards?token=${token}`}>Список</Link>,
        key: "loyality_cards",
        icon: <SnippetsOutlined />,
      },
      {
        label: (
          <Link to={`/loyality_transactions?token=${token}`}>Транзакции</Link>
        ),
        key: "loyality_transactions",
        icon: <BarChartOutlined />,
      },
      {
        label: <Link to={`/loyality_settings?token=${token}`}>Настройки</Link>,
        key: "loyality_settings",
        icon: <SettingOutlined />,
      },
      {
        label: <Link to={`/analytics_cards?token=${token}`}>Отчеты</Link>,
        key: "analytics_cards",
        icon: <ClusterOutlined />,
      },
    ],
  },
  {
    label: "Контрагенты",
    key: "contragents",
    icon: <IdcardOutlined />,
    children: [
      {
        label: <Link to={`/contragents?token=${token}`}>Список</Link>,
        key: "contragents",
        icon: <SolutionOutlined />,
      },
      {
        label: (
          <Link to={`/contragents_handsontable?token=${token}`}>Данные</Link>
        ),
        key: "contragents_handsontable",
        icon: <TableOutlined />,
      },
    ],
  },
  {
    label: <Link to={`/integrations?token=${token}`}>Интеграции</Link>,
    key: "integrations",
    icon: <ApiOutlined />,
  },
  {
    label: <Link to={`/projects?token=${token}`}>Проекты</Link>,
    key: "projects",
    icon: <FundOutlined />,
  },
  {
    label: <Link to={`/users?token=${token}`}>Пользователи</Link>,
    key: "users",
    icon: <TeamOutlined />,
  },
  {
    label: <Link to={`/events?token=${token}`}>События</Link>,
    key: "events",
    icon: <HistoryOutlined />,
  },

  {
    label: <Link to={`/organizations?token=${token}`}>Организации</Link>,
    key: "organizations",
    icon: <BuildOutlined />,
  },

  {
    label: <Link to={`/rent?token=${token}`}>Аренда</Link>,
    key: "rent",
    icon: <TableOutlined />,
  },
  {
    label: <Link to={`/settings?token=${token}`}>Настройки</Link>,
    key: "settings",
    icon: <SettingOutlined />,
  },
  {
    label: (
      <a
        href={`https://${process.env.REACT_APP_APP_URL}/api/v1/docs`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Swagger API
      </a>
    ),
    key: "swaggerapi",
    icon: <CodeOutlined />,
  },
  {
    label: (
      <a
        href={`https://${process.env.REACT_APP_APP_URL}/docasaurus/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Документация
      </a>
    ),
    key: "api",
    icon: <FileMarkdownOutlined />,
  },
];

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [authError403, setAuthError403] = useState(false);
  const [CBInfoText, setCBInfoText] = useState(
    <span style={{ marginRight: 10 }}>
      <BankOutlined /> <b>Загрузка...</b>{" "}
    </span>
  );
  const [CBInfo, setCBInfo] = useState({});
  const [inviteToken, setInviteToken] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [payboxesData, setPayboxesData] = useState({});
  const [paymentsData, setPaymentsData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [usersData, setUsersData] = useState({});
  const [loyalityData, setLoyalityData] = useState({});
  const [organizationsData, setOrganizationsData] = useState({});
  const [loyalityTransactionsData, setLoyalityTransactionsData] = useState({});
  const [conteragentsData, setConteragentsData] = useState({});
  const [categoriesData, setCategoriesData] = useState({});
  const [nomenclatureData, setNomenclatureData] = useState({});
  const [manufacturersData, setManufacturersData] = useState({});
  const [unitsData, setUnitsData] = useState({});
  const [contractsData, setContractsData] = useState({});
  const [warehousesData, setWarehousesData] = useState({});
  const [warehousesDocsData, setWarehousesDocsData] = useState({});
  const [pricesData, setPricesData] = useState({});
  const [priceTypeData, setPriceTypeData] = useState({});
  const [loyalitySettingsData, setLoyalitySettingsData] = useState({});

  const fetchData = () => {
    let Loading = loaded.slice();
    console.log("init");
    apiRequests.cashboxes
      .getMeta()
      .then((response) => {
        var options_list = [];
        for (var i in response.data.cboxes) {
          options_list.push({
            label: (
              <a
                href={
                  `https://${process.env.REACT_APP_APP_URL}/?token=` +
                  response.data.cboxes[i].token
                }
                target="_self"
                rel="noopener noreferrer"
              >
                {response.data.cboxes[i].name}
              </a>
            ),
            key: response.data.cboxes[i].name,
          });
        }

        setCBInfo(response.data.cboxes[0]);
        setLoggedIn(true);
        setCBInfoText(
          <CBSelect
            token={params.token}
            CBOptions={options_list}
            CBData={response.data.cboxes}
          ></CBSelect>
        );
        setInviteToken(response.data.invite_token);
        apiRequests.payboxes
          .getItems({ token: params.token, limit: 35, offset: 0 })
          .then((response) => {
            var options_list = [];
            for (var i in response.data.result) {
              let color = "green";
              if (response.data.result[i].balance < 0) {
                color = "red";
              }
              options_list.push(
                <Option key={response.data.result[i].id}>
                  {response.data.result[i].name}:{" "}
                  <font color={color}>
                    {response.data.result[i].balance} руб.
                  </font>
                </Option>
              );
            }

            const uniquePayboxes = response.data.result.map((item) => {
              return { value: item.name };
            });

            setPayboxesData({
              payboxesCount: response.data.count,
              payboxesDS: response.data.result,
              payboxesSelect: options_list,
              payboxesMeta: uniquePayboxes,
            });
          });

        apiRequests.payments
          .getItems({ ...params, limit: 35, offset: 0 })
          .then((response) => {
            const nameValues = [
              ...new Set(response.data.result.map((item) => item.name)),
            ].filter((x) => x !== null);
            const tagValues = [
              ...new Set(response.data.result.map((item) => item.tags)),
            ].filter((x) => x !== null);

            const mergedTags = [
              ...new Set(
                [].concat.apply(
                  [],
                  tagValues.map((item) => item.split(","))
                )
              ),
            ];

            const uniqueNameValues = nameValues.map((item) => {
              return { value: item, label: item };
            });
            const uniqueTagValues = mergedTags.map((value, i) => {
              return {
                label: `${value}`,
                value: i,
              };
            });
            // const uniqueArticleValues = articleValues.map(item => { return { value: item } });
            const paymentCalendar = [
              ...new Set(response.data.result.map((item) => item)),
            ];

            const { Option } = Select;
            const children = [];
            for (let i = 0; i < uniqueTagValues.length; i++) {
              children.push(
                <Option key={uniqueTagValues[i].value}>
                  {uniqueTagValues[i].value}
                </Option>
              );
            }

            const metaDict = { names: uniqueNameValues, tags: uniqueTagValues };

            Loading[0] = true;
            setLoaded(Loading);

            setPaymentsData({
              paymentsCount: response.data.count,
              paymentsDS: response.data.result,
              paymentsMeta: metaDict,
              listPayment: paymentCalendar,
            });
          });

        apiRequests.projects
          .getItems({ token: params.token, limit: 35, offset: 0 })
          .then((response) => {
            var options_list = [];

            options_list.push(<Option key={0}>Не указывать</Option>);

            for (var i in response.data.result) {
              options_list.push(
                <Option key={response.data.result[i].id}>
                  {response.data.result[i].id}. {response.data.result[i].name}:{" "}
                  {response.data.result[i].proj_sum} руб.
                </Option>
              );
            }

            const uniqueProjects = response.data.result.map((item) => {
              return { value: item.name };
            });

            Loading[1] = true;
            setLoaded(Loading);

            setProjectData({
              projectsCount: response.data.count,
              projectsDS: response.data.result,
              projectsSelect: options_list,
              projectsMeta: uniqueProjects,
            });
          });

        apiRequests.users
          .getItems({ token: params.token, limit: 100, offset: 0 })
          .then((response) => {
            Loading[2] = true;
            setLoaded(Loading);

            setUsersData({
              usersCount: response.data.count,
              usersDS: response.data.result,
            });
          });

        apiRequests.loyalityCards
          .getItems({ token: params.token, limit: 100, offset: 0 })
          .then((response) => {
            Loading[3] = true;
            setLoaded(Loading);

            setLoyalityData({
              loyalityCount: response.data.count,
              loyalityDS: response.data.result,
            });
          });

        apiRequests.organizations
          .getItems({ token: params.token, limit: 100, offset: 0 })
          .then((response) => {
            Loading[4] = true;
            let options_list = [];
            for (var i in response.data.result) {
              options_list.push({
                value: `${response.data.result[i].id}`,
                label: response.data.result[i].short_name,
              });
            }

            setLoaded(Loading);

            setOrganizationsData({
              organizationsCount: response.data.length,
              organizationsDS: options_list,
            });
          });

        apiRequests.loyalityTransactions
          .getItems({ token: params.token, limit: 100, offset: 0 })
          .then((response) => {
            Loading[5] = true;
            setLoaded(Loading);

            setLoyalityTransactionsData({
              loyalityTransactionsCount: response.data.count,
              loyalityTransactionsDS: response.data.result,
            });
          });

        apiRequests.contragents
          .getItems({ token: params.token, limit: 35, offset: 0 })
          .then((response) => {
            var options_list = [];

            options_list.push(<Option key={0}>Не указывать</Option>);

            for (var i in response.data.result) {
              options_list.push(
                <Option key={response.data.result[i].id}>
                  {response.data.result[i].name}
                </Option>
              );
            }

            Loading[6] = true;
            setLoaded(Loading);

            const nameValues = [
              ...new Set(response.data.result.map((item) => item.name)),
            ].filter((x) => x !== null);
            const PhoneValues = [
              ...new Set(response.data.result.map((item) => item.phone)),
            ].filter((x) => x !== null);
            const InnValues = [
              ...new Set(response.data.result.map((item) => item.inn)),
            ].filter((x) => x !== null);

            const uniqueNameValues = nameValues.map((item) => {
              return { value: item };
            });
            const uniquePhoneValues = PhoneValues.map((item) => {
              return { value: item };
            });
            const uniqueInnValues = InnValues.map((item) => {
              return { value: item };
            });

            setConteragentsData({
              conteragentsCount: response.data.count,
              conteragentsDS: response.data.result,
              conteragentsSelect: options_list,
              conteragentsAC: {
                names: uniqueNameValues,
                phones: uniquePhoneValues,
                inns: uniqueInnValues,
              },
            });

            apiRequests.categories.getItems({ limit: 500 }).then((res) => {
              Loading[7] = true;
              setLoaded(Loading);

              setCategoriesData({
                categoriesDS: res.data.result,
                categoriesCount: res.data.count,
              });
            });

            apiRequests.nomenclature.getItems().then((res) => {
              Loading[8] = true;
              setLoaded(Loading);

              setNomenclatureData({
                nomenclatureDS: res.data.result,
                nomenclatureCount: res.data.count,
              });
            });
            apiRequests.manufacturers.getItems().then((res) => {
              Loading[9] = true;
              setLoaded(Loading);

              setManufacturersData({
                manufacturersDS: res.data.result,
                manufacturersCount: res.data.count,
              });
            });
            apiRequests.units
              .getItems({ token: params.token, limit: 500 })
              .then((res) => {
                Loading[10] = true;
                setLoaded(Loading);

                setUnitsData({
                  unitsDS: res.data.result,
                  unitsCount: res.data.count,
                });
              });
            apiRequests.organizations.getItems().then((res) => {
              Loading[11] = true;
              setLoaded(Loading);

              setOrganizationsData({
                organizationsDataDS: res.data.result,
                organizationsDataCount: res.data.count,
              });
            });
            apiRequests.contracts.getItems().then((res) => {
              Loading[12] = true;
              setLoaded(Loading);
              setContractsData({
                contractsDS: res.data.result,
                contractsCount: res.data.count,
              });
            });
            apiRequests.warehouses.getItems().then((res) => {
              Loading[13] = true;
              setLoaded(Loading);
              setWarehousesData({
                warehousesDS: res.data.result,
                warehousesCount: res.data.count,
              });
            });
            apiRequests.docs.warehouse().then((res) => {
              Loading[14] = true;
              setLoaded(Loading);
              setWarehousesDocsData({
                warehouseDocsDS: res.data.result,
                warehouseDocsCount: res.data.count,
              });
            });
            // apiRequests.prices.getItems({ ...params, limit: 12 }).then((res) => {
            //   Loading[15] = true;
            //   setLoaded(Loading);
            //   setPricesData({
            //     pricesDS: res.data.result,
            //     pricesCount: res.data.count,
            //   });
            // });
            apiRequests.prices.getTypes().then((res) => {
              Loading[16] = true;
              setLoaded(Loading);
              setPriceTypeData({
                priceTypeDS: res.data.result,
                priceTypeCount: res.data.count,
              });
            });
            apiRequests.loyalitySettings.getItems().then((res) => {
              Loading[17] = true;
              setLoaded(Loading);
              setLoyalitySettingsData({
                loyalitySettingsDS: res.data.result,
                loyalitySettingsCount: res.data.count,
              });
            });

            ws_connect();
          });

        apiRequests.account
          .getInfo()
          .then((response) => {
            setIsAdmin(response.data.is_owner);
            if (response.data.demo_left === 0 && response.data.balance <= 0) {
              Modal.confirm({
                title: "Предупреждение",
                content:
                  "Ваш демо-период истек, просим вас оплатить дальнейшее пользование системы",
                okType: "primary",
                okText: "Оплатить",
                closable: false,
                centered: true,
                maskClosable: false,
                cancelButtonProps: { style: { display: "none" } },
                onOk: () => {
                  window.location.href = `https://${response.data.link_for_pay}`;
                },
              });
            }
          })
          .catch((error) => {
            if (error.status === 404) {
              Modal.info({
                title: "Предупреждение",
                content: (
                  <p>
                    В вашем аккаунте не назначен тариф, пожалуйста обратитесь в
                    поддержку.
                  </p>
                ),
                onOk() {},
              });
            }
          });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setAuthError403(true);
        }
      });
  };

  useEffect(() => {
    fetchData();
    if (params.hideLayout) {
      window.document.body.classList.add("hide-layout");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ws, setWs] = useState(null);
  const [timeout, setTimeout] = useState(250);

  const ws_connect = () => {
    var Ws = new WebSocket(
      `wss://${process.env.REACT_APP_APP_URL}/ws/${params.token}/`
    );
    var connectInterval;

    Ws.onopen = () => {
      console.log("connected websocket main component");

      setWs(Ws);

      setTimeout(250);
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    // websocket onclose event listener
    Ws.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (timeout * 2) / 1000
        )} second.`,
        e.reason
      );

      setTimeout(timeout * 2);
      connectInterval = setTimeout(check, Math.min(10000, timeout)); //call check function after timeout
    };

    Ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      Ws.close();
    };
  };

  const check = () => {
    if (!ws || ws.readyState === WebSocket.CLOSED) this?.connect();
  };

  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const [loadingChecker, setLoadingChecker] = useState(true);

  useEffect(() => {
    if (loggedIn && loaded && ws) {
      setLoadingChecker(false);
    }
  }, [loggedIn, loaded, ws]);

  useEffect(() => {
    if (!loadingChecker) {
      const pathName = window.location.pathname;
      if (pathName === "/") {
        setSelected(["payments"]);
      } else {
        setSelected([pathName.split("/")[1]]);
      }
    }
  }, [location.pathname, loadingChecker]);

  if (!params.token) {
    return (
      <Result
        status="403"
        title={
          <>
            Вы не ввели токен <AlertOutlined />
            <br /> Пожалуйста, проверьте вашу ссылку.
          </>
        }
      />
    );
  }

  if (authError403) {
    return <AuthError />;
  }

  const routes = (
    <Routes>
      <Route
        path="/"
        element={
          <PaymentsWrapper
            PBData={payboxesData.payboxesSelect}
            PRData={projectData.projectSelect}
            payboxesMeta={payboxesData.payboxesMeta}
            paymentsMeta={paymentsData.paymentsMeta}
            projMeta={projectData.projectsMeta}
            query={params}
            caData={conteragentsData.conteragentsDS}
            caSel={conteragentsData.conteragentsSelect}
            caMeta={conteragentsData.conteragentsAC}
            websocket={ws}
          />
        }
      />
      <Route
        path="/payboxes"
        element={
          <TablePayboxes
            query={params}
            payboxesData={{
              c: payboxesData.payboxesCount,
              ds: payboxesData.payboxesDS,
            }}
            websocket={ws}
          />
        }
      />
      <Route
        path="/loyality_cards"
        element={
          <TableLoyalityCards
            query={params}
            orgsData={organizationsData.organizationsDataDS}
            loyalityCardsData={{
              c: loyalityData.loyalityCount,
              ds: loyalityData.loyalityDS,
            }}
            websocket={ws}
          />
        }
      />
      <Route
        path="/loyality_transactions"
        element={
          <TableLoyalityTransactions
            query={params}
            loyalityCardsData={loyalityData.loyalityDS}
            loyalityTransactionsData={{
              c: loyalityTransactionsData.loyalityTransactionsCount,
              ds: loyalityTransactionsData.loyalityTransactionsDS,
            }}
            websocket={ws}
          />
        }
      />
      <Route
        path="/analytecs"
        element={
          <TableAnalystics
            paymentsData={paymentsData.paymentsDS}
            listPayment={paymentsData.listPayment}
            websocket={ws}
            query={params}
            filter={true}
          />
        }
      />
      <Route
        path="/projects"
        element={
          <TableProjects
            c={projectData.projectsCount}
            ds={projectData.projectsDS}
            query={params}
            websocket={ws}
          />
        }
      />
      <Route
        path="/integrations"
        element={<TableIntegrations query={params} websocket={ws} />}
      />
      <Route
        path="/events"
        element={
          <TableEvents
            token={params.token}
            websocket={ws}
            users={usersData.usersDS}
          />
        }
      />
      <Route
        path="/users"
        element={
          <TableUsers
            c={usersData.usersCount}
            ds={usersData.usersDS}
            query={params}
            invite={inviteToken}
            websocket={ws}
          />
        }
      />
      <Route
        path="/contragents"
        element={
          <TableContragents
            query={params}
            c={contractsData.conteragentsCount}
            ds={contractsData.conteragentsDS}
            websocket={ws}
            // meta={this.state.CAAC}
          />
        }
      />
      <Route
        path="/categories"
        element={
          <TableCategoriesPage
            token={params.token}
            websocket={ws}
            initialData={categoriesData.categoriesDS}
          />
        }
      />
      <Route
        path="/nomenclature"
        element={
          <TableNomenclature
            token={params.token}
            websocket={ws}
            initialData={nomenclatureData.nomenclatureDS}
            nomenclatureDataCount={nomenclatureData.nomenclatureCount}
            manufacturersData={manufacturersData.manufacturersDS}
            categoriesData={categoriesData.categoriesDS}
            unitsData={unitsData.unitsDS}
          />
        }
      />
      <Route
        path="/organizations"
        element={
          <TableOrganizations
            token={params.token}
            websocket={ws}
            initialData={organizationsData.organizationsDataDS}
          />
        }
      />
      <Route
        path="/contracts"
        element={
          <TableContracts
            token={params.token}
            organizationsData={organizationsData.organizationsDataDS}
            conteragentsData={conteragentsData.conteragentsDS}
            websocket={ws}
            initialData={contractsData.contractsDS}
          />
        }
      />
      <Route
        path="/warehouses"
        element={
          <TableWarehouses
            token={params.token}
            websocket={ws}
            initialData={warehousesData.warehousesDS}
          />
        }
      />
      <Route
        path="/warehouses_balances"
        element={
          <TableWarehousesBalance
            isAdmin={isAdmin}
            token={params.token}
            websocket={ws}
            initialData={warehousesData.warehousesDS}
          />
        }
      />
      <Route
        path="/docs_warehouse"
        element={
          <TableDocsWarehouse
            token={params.token}
            query={params}
            websocket={ws}
            initialData={warehousesDocsData.warehouseDocsDS}
            docsCount={warehousesDocsData.warehouseDocsCount}
            warehousesData={warehousesData.warehousesDS}
            nomenclatureData={nomenclatureData.nomenclatureDS}
            organizationsData={organizationsData.organizationsDataDS}
            unitsData={unitsData.unitsDS}
            priceTypesData={priceTypeData.priceTypeDS}
          />
        }
      />
      <Route
        path="/prices"
        element={
          <TablePrices
            params={params}
            token={params.token}
            websocket={ws}
            initialData={pricesData.pricesDS}
            countData={pricesData.pricesCount}
            priceTypesData={priceTypeData.priceTypeDS}
            manufacturersData={manufacturersData.manufacturersDS}
            nomenclatureData={nomenclatureData.nomenclatureDS}
            categoriesData={categoriesData.categoriesDS}
            unitsData={unitsData.unitsDS}
            setPricesData={setPricesData}
          />
        }
      />
      <Route
        path="/price_types"
        element={
          <TablePriceType
            loading={Loading[16]}
            token={params.token}
            priceTypeData={priceTypeData}
            setPriceType={setPriceTypeData}
          />
        }
      />
      <Route
        path="/loyality_settings"
        element={
          <TableLoyalitySettings
            params={params}
            token={params.token}
            websocket={ws}
            initialData={loyalitySettingsData.loyalitySettingsDS}
            organizationsData={organizationsData.organizationsDataDS}
          />
        }
      />
      <Route
        path="/analytics_cards"
        element={
          <TableLoyalityReport
            params={params}
            token={params.token}
            websocket={ws}
          />
        }
      />
      <Route
        path="/docs_sales"
        element={<TableDocsSales query={params} websocket={ws} />}
      />
      <Route
        path="/docs_purchases"
        element={
          <TablePurchases
            contractsData={contractsData.contractsDS}
            warehousesDS={warehousesData.warehousesDS}
            contragentsInfo={conteragentsData.conteragentsDS}
            organizationsDS={organizationsData.organizationsDataDS}
            query={params}
            websocket={ws}
          />
        }
      />
      <Route
        path="/nomenclature_handsontable"
        element={
          <TablePricesHandsontable token={params.token} websocket={ws} />
        }
      />
      <Route
        path="/contragents_handsontable"
        element={
          <TableContragentsHandsontable token={params.token} websocket={ws} />
        }
      />
      <Route path="/rent" element={<RentalChart token={params.token} />} />
      <Route
        path="/settings/amo_triggers"
        element={<AmoTriggers token={params.token} />}
      />
      <Route path="/settings" element={<Settings token={params.token} />} />
      <Route
        path="/nomenclature"
        element={
          <TableNomenclature
            token={params.token}
            websocket={ws}
            initialData={nomenclatureData.nomenclatureDS}
            nomenclatureDataCount={nomenclatureData.nomenclatureCount}
            manufacturersData={manufacturersData.manufacturersDS}
            categoriesData={categoriesData.categoriesDS}
            unitsData={unitsData.unitsDS}
          />
        }
      />
    </Routes>
  );

  if (params.hideLayout) {
    return (
      <ConfigProvider locale={ruRU}>
        {loadingChecker ? (
          <LoadingState />
        ) : (
          <QueryClientProvider client={queryClient}>
            {routes}
          </QueryClientProvider>
        )}
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider locale={ruRU}>
      {loadingChecker ? (
        <LoadingState />
      ) : (
        <QueryClientProvider client={queryClient}>
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{
                background: "white",
                height: "100vh",
                overflow: "auto",
                position: "sticky",
                left: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={selected}
                selectedKeys={selected}
                items={nav_items(params.token)}
              />
            </Sider>
            <CookieAcceptNotification></CookieAcceptNotification>
            <Layout>
              <Header
                style={{
                  padding: 0,
                  background: "white",
                  position: "sticky",
                  top: 0,
                  zIndex: 1000,
                }}
              >
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                  }}
                />

                <div style={{ float: "right" }}>
                  {/*<Button href={'https://t.me/natfullinrus'} style={{ marginRight: '15px' }} type={'primary'}>*/}
                  {/*  Оплатить*/}
                  {/*</Button>*/}
                  {/* balance info */}
                  {CBInfoText}
                </div>
              </Header>

              <Content className="site-layout">
                <div
                  className="site-layout-background"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 15,
                    minHeight: "83.5vh",
                  }}
                >
                  {routes}
                </div>
              </Content>

              <Footer
                style={{
                  textAlign: "center",
                }}
              >
                TableCRM © {new Date().getFullYear()} Платформа для учета
                финансов
              </Footer>
            </Layout>

            <FloatButton
              href={`https://t.me/tablecrmhelpbot?start=${CBInfo.name
                ?.split("_")
                .pop()}${location.pathname.slice(1, location.pathname.length)}`}
              icon={<CommentOutlined />}
              trigger="click"
              style={{ right: 24 }}
              tooltip={
                <>
                  Мы на связи с 9:00-19:00 c Пн-Пт.
                  <br />
                  Кликните для перехода в телеграм-бот.
                </>
              }
            />
          </Layout>
        </QueryClientProvider>
      )}
    </ConfigProvider>
  );
};

export default App;
