import { Button, Modal, Form, Input, Select, Row, Col, Alert } from "antd";
import { EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { apiRequests } from "src/shared/api/api";
import { DebounceSelect } from "src/shared";

function TriggerModalUpdate({ record, updateListTrigger }) {
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState(null);

  const handlerShowModal = () => {
    setShowModal(true);
  };

  const fetchAmoBots = async (name = "") => {
    return await apiRequests.amo_bots
      .getItemsByName({ name })
      .then((data) => {
        return data.data.map((item) => ({ value: item.id, label: item.name }));
      })
      .then((body) => {
        return body;
      });
  };

  const handlerUpdate = async (value) => {
    console.log(value);
    await apiRequests.triggers
      .patch(record.id, {
        ...value,
        amo_bots_id: value.amo_bots_id.value ? value.amo_bots_id.value : value.amo_bots_id,
      })
      .then((data) => {
        setShowModal(false);
        updateListTrigger();
      })
      .catch((err) => {
        setError((prev) => ({ ...prev, text: err.message.detail }));
      });
  };

  useEffect(() => {
    if (showModal) {
      form.setFieldValue("active", record.active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <>
      <Button icon={<EditOutlined />} onClick={handlerShowModal} />

      <Modal
        title={"Редактирование триггера"}
        open={showModal}
        maskClosable={false}
        onOk={() =>
          form
            .validateFields()
            .then((values) => {
              form.submit();
            })
            .catch((err) => {})
        }
        onCancel={() => {
          setShowModal(false);
          form.resetFields();
        }}
        onClose={() => {
          setShowModal(false);
          form.resetFields();
        }}
        okText={"Сохранить"}>
        {error?.text && (
          <Alert
            type="error"
            style={{ margin: "16px 0px" }}
            closable
            message={error?.text}
            onClose={() => setError({})}
          />
        )}
        <Form
          form={form}
          onFinish={handlerUpdate}
          initialValues={{ ...record, amo_bots_id: { value: record.amo_bot.id, label: record.amo_bot.name } }}>
          <Form.Item
            name={"name"}
            label={"Название"}
            rules={[{ required: true, message: "укажите название триггреа" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name={"amo_bots_id"}
            label={"Бот amoCRM"}
            rules={[{ required: true, message: "выберите бота amoCRM" }]}>
            <DebounceSelect fetchOptions={fetchAmoBots} labelInValue={false} />
          </Form.Item>
          <Row gutter={[10]}>
            <Col span={8}>
              <Form.Item name={"type"} rules={[{ required: true, message: "укажите тип" }]}>
                <Select style={{ width: "100%" }}>
                  <Select.Option value={"до"} />
                  <Select.Option value={"после"} />
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={"time_variant"} rules={[{ required: true, message: "укажите временной интервал" }]}>
                <Select style={{ width: "100%" }}>
                  <Select.Option value={"дни"} />
                  <Select.Option value={"часы"} />
                  <Select.Option value={"минуты"} />
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={"time"} rules={[{ required: true, message: "укажите время" }]}>
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item name={"active"} label={"Пауза"}>
            <Switch />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default TriggerModalUpdate;
